import React from 'react'
import { graphql } from 'gatsby'
import { Image } from 'react-datocms'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import media from '../styles/mq'

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  ${media.greaterThan('medium')} {
    flex-direction: row;
    align-items: flex-end;
  }
  > div {
    ${media.greaterThan('medium')} {
      :first-of-type {
        order: 2;
      }
      padding: 0 var(--space-medium);
      flex: 1;
    }
  }
`

const Booking = styled.div`
  margin-top: var(--space-large);
  margin-bottom: var(--space-small);
  > h4 {
    font-size: 1.1rem;
  }
`

const fixedImgOnMobileCss = css`
  ${media.lessThan('medium')} {
    display: block !important;
    width: 100% !important;
    height: 40vh;
    margin-bottom: var(--space-larger);
    > div {
      padding-top: 0 !important;
      height: 100% !important;
      max-width: none !important;
    }
    > picture {
      height: 100%;
    }
  }
`

const ContatPage = ({
  data: {
    dato: { page }
  }
}) => {
  const { title, cover, contacts } = page

  return (
    <Flex className="block c-large">
      <div>
        <Image data={cover.responsiveImage} css={fixedImgOnMobileCss} />
      </div>
      <div>
        <h2 style={{ marginTop: 0 }}>{title}</h2>
        {contacts.map(({ email, phone, title }, index) => (
          <Booking key={index}>
            <h4>{title}</h4>
            <div>
              <a href={`tel:${phone}`} itemProp="telephone">
                {phone} →
              </a>
            </div>
            <div>
              <a
                href={`mailto:${email}`}
                itemProp="email"
                style={{ textDecoration: 'underline' }}
              >
                {email}
              </a>
            </div>
          </Booking>
        ))}
      </div>
    </Flex>
  )
}

export default ContatPage

export const query = graphql`
  query($locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      page: pageContact(locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        title
        cover {
          responsiveImage(
            imgixParams: { fit: crop, w: 900, h: 1280, auto: format }
          ) {
            ...ResponsiveImageFragment
          }
        }
        contacts {
          email
          phone
          title
        }
      }
    }
  }
`
